import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'
import { getImage, StaticImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

function Loans() {
  const { heroImage, avatar } = useStaticQuery(query);
  const bgImage = convertToBgImage(getImage(heroImage));

  return (
    <Layout>
      <SEO title="Loans, Interest Free Loans" />

      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
        className="-mt-32 h-screen object-cover"
      />

      <section className="py-5 px-4">
        <div className="container max-w-5xl -mt-48 lg:-mt-80 mx-auto">
          <div className="p-2 bg-gray-500 opacity-90">
            <h1 className="font-extrabold text-4xl text-center text-white">Loan Types</h1>
          </div>          
          <div className="mt-20 bg-white shadow-lg text-gray-800">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <StaticImage
                className="object-cover h-full"
                src="../images/abed-ismail.png"
                alt="abed ismail car image"
                quality={95}                    
              />
              <div className="p-6">
                <h2 className="font-bold text-xl text-center">Business Financing</h2>
                <div className="mt-5 text-sm">
                  The SACCO will provide business financing to members who wish to engage in business 
                  activities and need capital to finance their business. The SACCO will carry out due 
                  diligence on the business or the idea and if convinced of its viability will engage 
                  on profit and loss sharing type of finance with the business. The idea of business 
                  financing brings with it a big risk and as such the SACCO employ rigorous processes 
                  to safe guard the business owner and the SACCO. The preferred contract type will be 
                  profit sharing and therefore honesty in reporting and book keeping will be of utmost importance.
                </div>
                <div className="mt-5 text-center">
                  <a className="py-2 px-4 rounded-full bg-[#CFEDFF] hover:font-bold hover:bg-cyan-500"
                    href="https://members.hayaansacco.co.ke" target="_blank" rel="noopener noreferrer">Apply Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 px-4">
        <div className="container max-w-5xl mx-auto shadow bg-white text-gray-800">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="p-6 order-last md:order-first">
              <h2 className="font-bold text-xl text-center">Livestock Wealth Management</h2>
              <div className="mt-5 text-sm">
                Hayaan Intends to offer innovative livestock wealth management solutions 
                to pastoralist communities as well others interested in investing in this 
                industry. One of the main objectives of this idea is to enable Pastoralists 
                to develop alternative assets over time from their livestock, whilst still 
                practicing their traditional lifestyle. This will enable them to be resilient 
                to droughts and other climate related seasonal changes by developing and 
                redeeming at the time of need alternative assets an sources of income.
              </div>
              <div className="mt-5 text-center">
                <a className="py-2 px-4 rounded-full bg-[#CFEDFF] hover:font-bold hover:bg-cyan-500"
                  href="https://members.hayaansacco.co.ke" target="_blank" rel="noopener noreferrer">Apply Now</a>
              </div>
            </div>
            <StaticImage
              className="object-cover h-full"
              src="../images/wild-life.png"
              alt="Wild life image"
              quality={95}                    
            />
          </div>
        </div>
      </section>

      <section className="py-10 px-4">
        <div className="container max-w-5xl mx-auto shadow bg-white text-gray-800">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <StaticImage
              className="object-cover h-full"
              src="../images/car-garage.png"
              alt="Car garage image"
              quality={95}                    
            />
            <div className="p-6">
              <h2 className="font-bold text-xl text-center">Investments</h2>
              <div className="mt-5 text-sm">
                <p>
                  Hayaan SACCO will engage in professionally run and high return investments 
                  in Halal activities ranging from real estate, livestock, horticulture and 
                  other industries. This will ensure members and other who save with Hayaan 
                  get high returns on their capital. Some of the proposed investment areas for hayaan includes:
                </p>
                <ul className="list-disc p-5">
                  <li>Light manufacturing: value additions to Milk, Leather processing, Fruits and Horticulture</li>
                  <li>Energy sector investments</li>
                  <li>Trading and retail</li>
                  <li>Real estate and property development</li>
                </ul>
              </div>
              <div className="mt-5 text-center">
                <a className="py-2 px-4 rounded-full bg-[#CFEDFF] hover:font-bold hover:bg-cyan-500"
                  href="https://members.hayaansacco.co.ke" target="_blank" rel="noopener noreferrer">Apply Now</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 px-4">
        <div className="container max-w-5xl mx-auto shadow bg-white text-gray-800">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="p-6 order-last md:order-first">
              <h2 className="font-bold text-xl text-center">Home Ownership Schemes</h2>
              <div className="mt-5 text-sm">
                Provide interest free Mortgages and cheaper housing through economy of Scale. 
                These will include programmes such as rent to own and other innovative home ownership schemes.
              </div>
              <div className="mt-5 text-center">
                <a className="py-2 px-4 rounded-full bg-[#CFEDFF] hover:font-bold hover:bg-cyan-500"
                  href="https://members.hayaansacco.co.ke" target="_blank" rel="noopener noreferrer">Apply Now</a>
              </div>
            </div>
            <StaticImage
              className="object-cover h-full"
              src="../images/home-ownership.png"
              alt="Home ownership image"
              quality={95}                    
            />
          </div>
        </div>
      </section>

    </Layout>
  )
}

const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "loans-hero-bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    avatar: file(relativePath: { eq: "avatar.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Loans
